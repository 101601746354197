import {createSpinner, showSpinner, hideSpinner, setSpinner} from '@syncfusion/ej2-popups';

export default {
    data() {
        return {
            modalsOpened: [],
        }
    },
    methods: {
        loadPage(componentName) {
            this.tmpMainTemplateName = componentName;
        },

        loadModal(scope, modalComponentName, initData) {
            if (initData === undefined || initData === null) {
                initData = {};
            }

            let modalObject = {
                modalComponent: modalComponentName,
                modalData: initData
            };

            this.modalsOpened.push(modalObject);
        },
    },
    watch: {},
    created() {

    },
    mounted() {
        eventBus.$on('showAppModal', (scope, modalComponentName, initData) => {
            this.loadModal(scope, modalComponentName, initData);
        });

        eventBus.$on('closeAppModal', (eventData) => {
            this.modalsOpened = this.modalsOpened.filter(m => m.modalComponent !== eventData.modalComponent);
            document.getElementById(eventData.modalComponent).parentElement.remove();
        });
    }
}