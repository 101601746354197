<template src="./usersEditModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
    import states from '../../../dmFramework/dmJS/dmStates'

    export default {
        name: 'users-edit-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'usersEditModal' + DM.getRandomGuid(),
                lblTitle: 'Add User',
                userType: '',
                userTypeOptions: null,
                emailAddress: '',
                emailAddressConfirm: '',
                password: '',
                passwordConfirm: '',
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                stateOptions: states,
                zipCode: '',
                phone: '',
                hospitals: [],
                selectedHospitals: [],
                hospitalOptions: [],
                serviceType: '',
                serviceTypeOptions: [],
                notes: '',
                mode: '',
                showServiceTypeOptions: false,
                serviceTypePlaceholder: ''
            }
        },
        methods: {
            saveUser() {
                let url = '/Users/Create';
                let data = DM.copy(this.modalData.user);

                if (this.modalData.mode === 'edit') {
                    data.UserID = this.modalData.user.UserID;
                    url = '/Users/Update';
                }

                let serviceTypeID = -1;
                if (this.serviceType) {
                    serviceTypeID = this.serviceTypeOptions.filter(s => s.text === this.serviceType)[0].value;
                }

                let hospitalIDs = this.hospitals.map(h => { return { FacilityID: this.hospitalOptions.filter(o => o.text === h)[0].value } });

                delete data.UserCreatedUtc;
                delete data.UserUpdatedUtc;
                delete data.UserIsDeleted;
                // delete data.userType;

                Object.assign(data, {
                    UserEmailAddress: this.emailAddress,
                    Password: this.modalData.mode === 'edit' ? undefined : this.password,
                    PasswordConfirm: this.modalData.mode === 'edit' ? undefined : this.passwordConfirm,
                    UserType: this.userType || '',
                    FirstName: this.firstName,
                    LastName: this.lastName,
                    UserAddress1: this.address1,
                    UserAddress2: this.address2,
                    UserCity: this.city,
                    UserState: this.state || '',
                    UserZipCode: this.zipCode,
                    UserMobilePhone: this.phone,
                    UserFacilities: hospitalIDs,
                    ServiceTypeID: serviceTypeID,
                    UserAdminNotes: this.notes
                });

                return DM.http({
                    method: "POST",
                    url: url,
                    data: data
                });
            },
            btnSaveOnClick() {
                this.saveUser()
                    .then(response => {
                        this.modalData.callback();
                        this.modalRef.hide();
                    });
            },
            btnSaveAndAddMoreOnClick() {
                this.saveUser()
                    .then(response => {
                        this.modalData.callback();
                        this.userType = null;
                        this.emailAddress = '';
                        this.emailAddressConfirm = '';
                        this.password = '';
                        this.passwordConfirm = '';
                        this.firstName = '';
                        this.lastName = '';
                        this.address1 = '';
                        this.address2 = '';
                        this.city = '';
                        this.state = null;
                        this.zipCode = '';
                        this.phone = '';
                        this.notes = '';
                        this.serviceType = null;
                        this.hospitals = [];
                    });
            },
            btnCancelOnClick() {
                this.modalRef.hide();
            },
            getServiceTypeOptions(facilityID) {
                return DM.http({
                    method: "GET",
                    url: "/ServiceTypes/GetByFacilityID",
                    params: {
                        FacilityID: facilityID
                    }
                }, true).then(response => {
                    response.forEach(type => {
                        if(!this.serviceTypeOptions.filter(o => o.value === type.ServiceTypeID)[0]) {
                            let dataObject = {text: type.ServiceTypeName, value: type.ServiceTypeID};
                            this.serviceTypeOptions.push(dataObject);
                        }
                    });

                    if(this.modalData.user.ServiceTypeID && this.modalData.user.ServiceTypeID !== -1) {
                        this.serviceType = this.serviceTypeOptions.filter(s => s.value === this.modalData.user.ServiceTypeID)[0].text;
                    }

                    setTimeout(() => {
                        this.showServiceTypeOptions = true;
                    });
                });
            },
            getHospitalOptions() {
                return DM.http({
                    method: "GET",
                    url: "/Facilities/GetAll",
                }).then(response => {
                    response.forEach(type => {
                        let dataObject = {text: type.FacilityName, value: type.FacilityID};
                        this.hospitalOptions.push(dataObject);
                    });

                    if(this.mode === 'edit') {
                        this.hospitals = this.modalData.user.UserFacilities.map(f => {
                            return this.hospitalOptions.filter(o => o.value === f.FacilityID)[0].text
                        });

                        this.selectedHospitals = this.modalData.user.UserFacilities.map(f => {
                            return this.hospitalOptions.filter(o => o.value === f.FacilityID)[0]
                        });
                    }

                    this.hospitals.forEach(h => {
                        let hospitalID = this.hospitalOptions.filter(o => o.text === h)[0].value;
                        this.getServiceTypeOptions(hospitalID);
                    });
                });
            },
            hospitalsOnChange($event) {
                if($event.name === 'select') {
                    this.selectedHospitals.push($event.itemData);
                }

                if($event.name === 'removed') {
                    this.selectedHospitals = this.selectedHospitals.filter(h => h.value !== $event.itemData.value);
                }

                this.serviceTypeOptions = [];

                this.selectedHospitals.forEach(h => {
                    this.getServiceTypeOptions(h.value);
                });
            }
        },
        created() {
            this.getHospitalOptions();

            let user = this.modalData.user;

            this.mode = this.modalData.mode;

            if (this.modalData.mode === 'edit') {
                this.lblTitle = 'Edit User: ' + this.modalData.user.FirstName + ' ' + this.modalData.user.LastName;
                this.userType = user.UserType;
                this.emailAddress = user.UserEmailAddress;
                this.firstName = user.FirstName;
                this.lastName = user.LastName;
                this.address1 = user.UserAddress1;
                this.address2 = user.UserAddress2;
                this.city = user.UserCity;
                this.state = user.UserState;
                this.zipCode = user.UserZipCode;
                this.phone = user.UserMobilePhone;
                this.notes = user.UserAdminNotes;
            }

            this.userTypeOptions = this.modalData.userTypeOptions;
        },
        mounted() {

        }
    }
</script>
