<template src="./rehabOrdersPage.html"></template>

<script>
    import Vue from 'vue'

    export default {
        name: 'rehab-orders-page',
        data: function () {
            return {
                grdRehabOrdersFields: [],
                grdRehabOrdersItems: [],
                grdRehabOrdersSortBy: null,
                grdRehabOrdersSortByHospital: {
                    columns: [
                        {field: 'PatientLastName', direction: 'Ascending'},
                    ]
                },
                grdRehabOrdersSortByTherapist: {
                    columns: [
                        {field: 'RehabOrderCreatedUtc', direction: 'Ascending'},
                    ]
                },
                grdRehabOrdersClass: 'dm-bg-color-black',
                hospital: '',
                hospitalOptions: '',
                orderStatus: '',
                orderStatusOptions: [],
                serviceType: '',
                serviceTypeOptions: [],
                searchText: '',
                grdRehabOrdersActionsHospital: [
                    {
                        text: 'Edit'
                    },
                    {
                        text: 'Delete'
                    }
                ],
                grdRehabOrdersActionsTherapistPending: [
                    {
                        text: 'View'
                    },
                    {
                        text: 'Add Time/Treatment Log'
                    },
                    {
                        text: 'Mark as Complete'
                    }
                ],
                grdRehabOrdersActionsTherapistCompleted: [
                    {
                        text: 'View'
                    },
                    {
                        text: 'Add Time/Treatment Log'
                    },
                    {
                        text: 'Mark as Pending'
                    }
                ],
                selectedRow: null,
                hospitals: [],
                isTherapist: app.signedInUser.UserType === 'Therapist'
            }
        },
        methods: {
            getRehabOrders() {
                let url = '/RehabOrders/GetByFacilityID';

                if(app.signedInUser.UserType === 'Therapist') {
                   url = '/RehabOrders/GetForSelf'
                }

                DM.http({
                    method: 'GET',
                    url: url,
                    params: {
                        FacilityID: app.signedInUser.UserFacilities[0].FacilityID
                    }
                }).then(response => {
                    response.forEach(order => {
                        order.serviceType = this.serviceTypeOptions.filter(type => type.value === order.ServiceTypeID)[0].text;
                        if(app.signedInUser.UserType === 'Therapist') {
                            order.hospitalName = this.hospitals.filter(h => h.FacilityID === order.FacilityID)[0].FacilityName;
                        }
                    });

                    this.rehabOrders = response;
                    this.grdRehabOrdersItems = response;
                });
            },
            getServiceTypeOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/ServiceTypes/GetByFacilityID',
                    params: {
                        FacilityID: app.signedInUser.UserFacilities[0].FacilityID
                    }
                }).then(response => {
                    response.forEach(type => {
                        let dataObject = {text: type.ServiceTypeName, value: type.ServiceTypeID};
                        this.serviceTypeOptions.push(dataObject);
                    });
                });
            },
            getOrderStatusOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/RehabOrders/GetAllOrderStatuses'
                }).then(response => {
                    response.forEach(status => {
                        let dataObject = {text: status, value: status};
                        this.orderStatusOptions.push(dataObject);
                    });
                });
            },
            grdRehabOrdersRowOnClick(args) {
                this.selectedRow = args;
            },
            addNewOrder() {
                eventBus.$emit('showAppModal', this, 'rehabOrdersEditModal', {
                    mode: 'new',
                    rehabOrder: {},
                    serviceTypeOptions: this.serviceTypeOptions,
                    callback: this.getRehabOrders
                });
            },
            cancelRehabOrder(rehabOrder) {
                let data = DM.copy(rehabOrder);
                delete data.RehabOrderCreatedUtc;
                delete data.RehabOrderUpdatedUtc;
                delete data.RehabOrderIsDeleted;
                delete data.serviceType;

                Object.assign(data, {
                    OrderStatus: 'Canceled'
                });

                DM.http({
                    method: 'POST',
                    url: '/RehabOrders/Update',
                    data: data
                })
                    .then(response => {
                        this.getRehabOrders();
                    });
            },
            grdRehabOrdersActionOnClick(args) {
                if (args.item.text === 'Edit') {
                    DM.http({
                        method: 'GET',
                        url: '/RehabOrders/GetByID',
                        params: {
                            RehabOrderID: this.selectedRow.rowData.RehabOrderID
                        }
                    }).then(response => {
                        response.serviceType = this.serviceTypeOptions.filter(type => type.value === response.ServiceTypeID)[0].text;

                        eventBus.$emit('showAppModal', this, 'rehabOrdersEditModal', {
                            mode: 'edit',
                            rehabOrder: response,
                            serviceTypeOptions: this.serviceTypeOptions,
                            callback: this.getRehabOrders
                        });
                    });
                }

                if (args.item.text === 'View') {
                    DM.http({
                        method: 'GET',
                        url: '/RehabOrders/GetByID',
                        params: {
                            RehabOrderID: this.selectedRow.rowData.RehabOrderID
                        }
                    }).then(response => {
                        response.serviceType = this.serviceTypeOptions.filter(type => type.value === response.ServiceTypeID)[0].text;

                        eventBus.$emit('showAppModal', this, 'rehabOrdersEditModal', {
                            mode: 'view',
                            rehabOrder: response,
                            serviceTypeOptions: this.serviceTypeOptions,
                            callback: () => {

                            }
                        });
                    });
                }

                if(args.item.text === 'Mark as Complete' || args.item.text === 'Mark as Pending') {
                    let data = {
                        RehabOrderID: this.selectedRow.rowData.RehabOrderID,
                        OrderStatus: this.selectedRow.rowData.OrderStatus === 'Pending' ? 'Completed' : 'Pending',
                        RehabOrderRowVersion: this.selectedRow.rowData.RehabOrderRowVersion,
                    };

                    DM.http({
                        method: 'POST',
                        url: '/RehabOrders/UpdateByTherapistUser',
                        data: data
                    }).then(response => {
                        this.getRehabOrders();
                    });
                }

                if (args.item.text === 'Delete') {
                    DM.confirmShow('Are you sure you want to delete this rehab order?', '', () => {
                        this.cancelRehabOrder(this.selectedRow.rowData);
                    }, null, null, 'No');
                }

                let rehabOrder = null;
                let timeLogs = null;
                let treatmentLogs = null;
                if (args.item.text === 'Add Time/Treatment Log') {
                    DM.http({
                        method: 'GET',
                        url: '/RehabOrders/GetByID',
                        params: {
                            RehabOrderID: this.selectedRow.rowData.RehabOrderID
                        }
                    }).then(response => {
                        response.serviceType = this.serviceTypeOptions.filter(type => type.value === response.ServiceTypeID)[0].text;
                        response.hospitalName = this.selectedRow.rowData.hospitalName;
                        rehabOrder = response;

                        return DM.http({
                            method: 'GET',
                            url: '/TimeLogs/GetByRehabOrderID',
                            params: {
                                RehabOrderID: this.selectedRow.rowData.RehabOrderID
                            }
                        });
                    }).then(response => {
                        timeLogs = response;
                        timeLogs.forEach(l => {
                            l.TimeStartUtc = l.TimeStartUtc ? new Date(l.TimeStartUtc) : null;
                            l.TimeEndUtc = l.TimeEndUtc ? new Date(l.TimeEndUtc) : null;
                        });

                        return DM.http({
                            method: 'GET',
                            url: '/TreatmentLogs/GetByRehabOrderID',
                            params: {
                                RehabOrderID: this.selectedRow.rowData.RehabOrderID
                            }
                        });
                    }).then(response => {
                        treatmentLogs = response;
                        treatmentLogs.forEach(l => {
                            l.TreatmentDate = l.TreatmentDate ? new Date(l.TreatmentDate) : null;
                        });

                        eventBus.$emit('showAppModal', this, 'rehabOrdersTimeTreatmentLogEditModal', {
                            mode: 'edit',
                            rehabOrder: rehabOrder,
                            timeLogs: timeLogs,
                            treatmentLogs: treatmentLogs,
                            callback: this.getRehabOrders
                        });
                    });
                }
            },
            searchOrders() {
                let filteredRehabOrders = this.rehabOrders;

                if (this.serviceType) {
                    filteredRehabOrders = filteredRehabOrders.filter(o => o.serviceType === this.serviceType);
                }

                if (this.hospital) {
                    filteredRehabOrders = filteredRehabOrders.filter(o => o.hospitalName === this.hospital);
                }

                if (this.orderStatus) {
                    filteredRehabOrders = filteredRehabOrders.filter(o => o.OrderStatus === this.orderStatus);
                }

                if (this.searchText) {
                    filteredRehabOrders = filteredRehabOrders.filter(
                        o => o.PatientFirstName.includes(this.searchText) || o.PatientLastName.includes(this.searchText) || o.RehabOrderNotes.includes(this.searchText)
                    );
                }

                this.grdRehabOrdersItems = filteredRehabOrders;
            },
            getFacilities() {
                let promises = [];

                app.signedInUser.UserFacilities.forEach(h => {
                    promises.push(DM.http({
                        method: 'GET',
                        url: '/Facilities/GetByID',
                        params: {
                            FacilityID: h.FacilityID
                        }
                    }));
                });

                return Promise.all(promises)
                    .then(responses => {
                        this.hospitals = responses;
                        this.hospitalOptions = this.hospitals.map(h => { return {text: h.FacilityName, value: h.FacilityID} });
                    });
            }
        },
        created() {
            this.getOrderStatusOptions();

            this.getServiceTypeOptions()
                .then(() => {
                    return this.getFacilities();
                })
                .then(() => {
                    this.getRehabOrders();
                });

            let self = this;

            this.grdRehabOrdersFields = [
                {
                    type: 'template',
                    width: '80px',
                    template: (args) => {
                        return {
                            template: Vue.component('columnTemplate', {
                                template: `<div>
                                                <ejs-dropdownbutton
                                                    v-if="!isTherapist"
                                                    :items="grdRehabOrdersActionsHospital"
                                                    :select="select"
                                                    iconCss="e-icons e-ellipsis-vert"
                                                    cssClass="e-caret-hide">
                                                </ejs-dropdownbutton>
                                                <ejs-dropdownbutton
                                                    v-if="isTherapist && data.OrderStatus === 'Pending'"
                                                    :items="grdRehabOrdersActionsTherapistPending"
                                                    :select="select"
                                                    iconCss="e-icons e-ellipsis-vert"
                                                    cssClass="e-caret-hide">
                                                </ejs-dropdownbutton>
                                                <ejs-dropdownbutton
                                                    v-if="isTherapist && data.OrderStatus === 'Completed'"
                                                    :items="grdRehabOrdersActionsTherapistCompleted"
                                                    :select="select"
                                                    iconCss="e-icons e-ellipsis-vert"
                                                    cssClass="e-caret-hide">
                                                </ejs-dropdownbutton>
                                           </div>`,
                                data: function () {
                                    return {
                                        grdRehabOrdersActionsHospital: self.grdRehabOrdersActionsHospital,
                                        grdRehabOrdersActionsTherapistPending: self.grdRehabOrdersActionsTherapistPending,
                                        grdRehabOrdersActionsTherapistCompleted: self.grdRehabOrdersActionsTherapistCompleted,
                                        isTherapist: self.isTherapist
                                    }
                                },
                                methods: {
                                    select(args) {
                                        self.grdRehabOrdersActionOnClick(args);
                                    }
                                }
                            })
                        }
                    }
                },
                {
                    key: 'PatientLastName',
                    label: 'Patient Last Name'
                },
                {
                    key: 'PatientFirstName',
                    label: 'Patient First Name'
                },
                {
                    key: 'serviceType',
                    label: 'Service Type'
                },
                {
                    key: 'RehabOrderID',
                    label: 'Order Number',
                    classes: {
                        class: 'dm-text-right'
                    }
                },
                {
                    key: 'RehabOrderCreatedUtc',
                    label: 'Date Created',
                    type: 'date'
                },
                {
                    key: 'OrderStatus',
                    label: 'Order Status',
                },
                {
                    key: 'RehabOrderNotes',
                    label: 'Notes',
                    width: '300px'
                },
            ];

            if(app.signedInUser.UserType === 'Therapist') {
                this.grdRehabOrdersSortBy = this.grdRehabOrdersSortByTherapist;

                this.grdRehabOrdersFields.splice(3, 0, {
                    key: 'hospitalName',
                    label: 'Hospital'
                });
            } else {
                this.grdRehabOrdersSortBy = this.grdRehabOrdersSortByHospital;
            }
        },
        mounted() {

        }
    }
</script>
