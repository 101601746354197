<template src="./hospitalReportsPage.html"></template>

<script>
    import {Sort} from "@syncfusion/ej2-vue-grids";

    export default {
        name: 'hospital-reports-page',
        data: function () {
            return {

            }
        },
        provide: {
            grid: [Sort]
        },
        methods: {

        },
        created() {

        },
        mounted() {

        }
    }
</script>
