var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dm-page-container dm-flex-column dm-padding-md dm-height-100"},[_c('div',{staticClass:"dm-flex-row dm-justify-content-space-between dm-align-items-center dm-margin-bottom-md"},[_c('h3',[_vm._v(" Users ")]),_c('ejs-button',{attrs:{"is-primary":"true"},nativeOn:{"click":function($event){return _vm.addUserOnClick($event)}}},[_c('span',{staticClass:"fa fa-plus-square"}),_vm._v(" Add User ")])],1),_c('div',{staticClass:"dm-flex-row dm-margin-bottom-md dm-align-items-flex-end"},[_c('ejs-dropdownlist',{attrs:{"dataSource":_vm.userTypeOptions,"floatLabelType":"Auto","placeholder":"User Type","width":'250px',"cssClass":"dm-margin-right-md","showClearButton":true},model:{value:(_vm.userType),callback:function ($$v) {_vm.userType=$$v},expression:"userType"}}),_c('ejs-textbox',{attrs:{"floatLabelType":"Auto","placeholder":"User Name, Notes","width":'300px',"showClearButton":true},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchUsersOnClick($event)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('ejs-button',{staticClass:"dm-margin-left-md dm-flex-none dm-margin-bottom-sm",nativeOn:{"click":function($event){return _vm.searchUsersOnClick($event)}}},[_vm._v(" Search ")])],1),_c('dm-table',{ref:"grdUsers",attrs:{"table":{
            grdTableItems: _vm.grdUsersItems,
            grdTableRowOnClick: _vm.grdUsersRowOnClick,
            grdTableActionOnClick: _vm.grdUsersActionOnClick,
            grdTableSortBy: _vm.grdUsersSortBy,
            grdTableFields: _vm.grdUsersFields,
            class: _vm.grdUsersClass
        },"items":_vm.grdUsersItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }