<template src="./adminReportsPage.html"></template>

<script>
    import {Sort} from "@syncfusion/ej2-vue-grids";

    export default {
        name: 'admin-reports-page',
        data: function () {
            return {
                grdAdminReportsTimeLogsFields: [
                    {
                        key: 'LastName',
                        label: 'Last Name'
                    },
                    {
                        key: 'FirstName',
                        label: 'First Name'
                    },
                    {
                        key: 'FacilityName',
                        label: 'Hospital',
                        width: '250px'
                    },
                    {
                        key: 'TimeLogCreatedUtc',
                        label: 'Date Created',
                        type: 'date'
                    },
                    {
                        key: 'timeIn',
                        label: 'Time In',
                    },
                    {
                        key: 'timeOut',
                        label: 'Time Out',
                    },
                    {
                        key: 'TotalTime',
                        label: 'Total Time',
                        classes: {
                            class: 'dm-text-right'
                        },
                        type: 'custom',
                        format: { format: "####.## 'mins'" },
                        aggregate: true,
                        aggregateColumn: {
                            doAggregation: (data) => {
                                if(!data.result) {
                                    return;
                                }

                                let total = 0;

                                data.result.forEach((item) => total += item.TotalTime);
                                return 'Total Time: ' + total + ' mins';
                            }
                        },
                        width: '100px'
                    },
                    {
                        key: 'RehabOrderID',
                        label: 'Rehab Order',
                        classes: {
                            class: 'dm-text-right'
                        },
                        width: '103px'
                    },
                ],
                grdAdminReportsTimeLogsItems: [],
                grdAdminReportsTimeLogsSortBy: null,
                grdAdminReportsTimeLogsSortByHospital: {
                    columns: [
                        {field: 'LastName', direction: 'Ascending'},
                    ]
                },
                grdAdminReportsTimeLogsToolbar: ['CsvExport'],
                grdAdminReportsTimeLogsClass: 'dm-bg-color-black',
                grdAdminReportsTimeLogsActionsHospital: [],
                grdAdminReportsTreatmentLogsFields: [
                    {
                        key: 'LastName',
                        label: 'Last Name'
                    },
                    {
                        key: 'FirstName',
                        label: 'First Name'
                    },
                    {
                        key: 'PatientLastName',
                        label: 'Patient Last Name'
                    },
                    {
                        key: 'PatientFirstName',
                        label: 'Patient First Name'
                    },
                    {
                        key: 'FacilityName',
                        label: 'Hospital',
                        width: '250px'
                    },
                    {
                        key: 'TreatmentDate',
                        label: 'Treatment Date',
                        type: 'date'
                    },
                    {
                        key: 'TreatmentMinutes',
                        label: 'Treatment Minutes',
                        classes: {
                            class: 'dm-text-right'
                        },
                        type: 'custom',
                        format: { format: "####.## 'mins'" },
                        aggregate: true,
                        aggregateColumn: {
                            doAggregation: (data) => {
                                if(!data.result) {
                                    return;
                                }
                                
                                let total = 0;
                                data.result.forEach((item) => total += item.TreatmentMinutes);
                                return 'Total Treatment Minutes: ' + total + ' mins';
                            }
                        },
                        width: '140px'
                    },
                    {
                        key: 'RehabOrderID',
                        label: 'Rehab Order',
                        classes: {
                            class: 'dm-text-right'
                        },
                        width: '103px'
                    },
                ],
                grdAdminReportsTreatmentLogsItems: [],
                grdAdminReportsTreatmentLogsSortBy: null,
                grdAdminReportsTreatmentLogsSortByHospital: {
                    columns: [
                        {field: 'LastName', direction: 'Ascending'},
                    ]
                },
                grdAdminReportsTreatmentLogsToolbar: ['CsvExport'],
                grdAdminReportsTreatmentLogsClass: 'dm-bg-color-black',
                grdAdminReportsTreatmentLogsActionsHospital: [],
                reportType: 'Time Logs',
                reportTypeOptions: [{
                    value: 'TimeLogs',
                    text: 'Time Logs'
                },{
                    value: 'TreatmentLogs',
                    text: 'Treatment Logs'
                }],
                dateRange: null,
                dateFieldOptionsTimeLog: [{
                    value: 'CreatedDate',
                    text: 'Created Date'
                },{
                    value: 'TimeIn',
                    text: 'Time In'
                },{
                    value: 'TimeOut',
                    text: 'Time Out'
                }],
                dateFieldOptionsTreatmentLog: [{
                    value: 'TreatmentDate',
                    text: 'Treatment Date'
                }],
                dateFieldOptions: [],
                dateField: '',
                hospital: '',
                hospitalOptions: [],
                therapist: '',
                therapistOptions: [],
                serviceType: '',
                serviceTypeOptions: [],
                searchText: '',
                selectedRow: null,
            }
        },
        provide: {
            grid: [Sort]
        },
        methods: {
            searchReports() {
                // let reportsLength = this.grdAdminReportsItems.length;
                // for(let x = 0; x < reportsLength; x++) {
                //     this.grdAdminReportsItems.pop();
                // }
                this.grdAdminReportsTimeLogsItems = [];
                this.grdAdminReportsTreatmentLogsItems = [];

                let url = '/TimeLogs/GetAllForReport';

                if(this.reportType === 'Treatment Logs') {
                    url = '/TreatmentLogs/GetAllForReport';
                }

                let serviceTypeID = -1;
                if (this.serviceType) {
                    serviceTypeID = this.serviceTypeOptions.filter(s => s.text === this.serviceType)[0].value;
                }

                let userID = -1;
                if (this.therapist) {
                    userID = this.therapistOptions.filter(s => s.text === this.therapist)[0].value;
                }

                let facilityID = -1;
                if (this.hospital) {
                    facilityID = this.hospitalOptions.filter(s => s.text === this.hospital)[0].value;
                }

                let startDate = null;
                let endDate = null;
                if (this.dateRange) {
                    startDate = this.dateRange[0];
                    endDate = this.dateRange[1]
                }

                DM.http({
                    method: "GET",
                    url: url,
                    params: {
                        StartDateUtc: startDate,
                        EndDateUtc: endDate,
                        DateField: this.dateField,
                        FacilityID: facilityID,
                        UserID: userID,
                        ServiceTypeID: serviceTypeID,
                    }
                }).then(response => {
                    response.forEach(log => {
                        if(this.reportType === 'Time Logs') {
                            log.timeIn = moment(log.TimeStartUtc).format('h:mm A');
                            log.timeOut = moment(log.TimeEndUtc).format('h:mm A');
                        }
                    });

                    if(this.searchText) {
                        response = response.filter(l => l.RehabOrderID == this.searchText ||
                                                        (l.PatientFirstName && l.PatientFirstName.toLowerCase().includes(this.searchText.toLowerCase())) ||
                                                        (l.PatientLastName && l.PatientLastName.toLowerCase().includes(this.searchText.toLowerCase())) ||
                                                        (l.PatientFirstName && this.searchText.toLowerCase().includes(l.PatientFirstName.toLowerCase())) ||
                                                        (l.PatientLastName && this.searchText.toLowerCase().includes(l.PatientLastName.toLowerCase())));

                    }


                    setTimeout(() => {
                        if(this.reportType === 'Time Logs') {
                            this.grdAdminReportsTimeLogsItems = response;
                        } else {
                            this.grdAdminReportsTreatmentLogsItems = response;
                        }
                    });
                });

            },
            grdAdminReportsTimeLogsRowOnClick(args) {
                this.selectedRow = args;
            },
            grdAdminReportsTimeLogsActionOnClick(args) {

            },
            grdAdminReportsTreatmentLogsRowOnClick(args) {
                this.selectedRow = args;
            },
            grdAdminReportsTreatmentLogsActionOnClick(args) {

            },
            getServiceTypeOptions() {
                return DM.http({
                    method: "GET",
                    url: "/ServiceTypes/GetAll",
                }).then(response => {
                    response.forEach(type => {
                        if(!this.serviceTypeOptions.filter(o => o.value === type.ServiceTypeID)[0]) {
                            let dataObject = {text: type.ServiceTypeName, value: type.ServiceTypeID};
                            this.serviceTypeOptions.push(dataObject);
                        }
                    });
                });
            },
            getHospitalOptions() {
                return DM.http({
                    method: "GET",
                    url: "/Facilities/GetAll",
                }).then(response => {
                    response.forEach(type => {
                        let dataObject = {text: type.FacilityName, value: type.FacilityID};
                        this.hospitalOptions.push(dataObject);
                    });
                });
            },
            getTherapists() {
                return DM.http({
                    method: "GET",
                    url: "/Users/GetTherapistsForDropdown",
                }).then(response => {
                    response.forEach(t => {
                        this.therapistOptions.push({text: t.TherapistName, value: t.UserID});
                    });
                });
            }
        },
        created() {
            this.getServiceTypeOptions();
            this.getHospitalOptions();
            this.getTherapists();

            this.dateFieldOptions = this.dateFieldOptionsTimeLog;
            this.dateField = 'Created Date';

            this.searchReports();

            this.$watch('reportType', (newVal) => {
                if(newVal === 'Time Logs') {
                    this.dateFieldOptions = this.dateFieldOptionsTimeLog;
                    setTimeout(() => {
                        this.dateField = 'Created Date';
                        this.searchReports();
                    });
                } else {
                    this.dateFieldOptions = this.dateFieldOptionsTreatmentLog;
                    setTimeout(() => {
                        this.dateField = 'Treatment Date';
                        this.searchReports();
                    });
                }
            });
        },
        mounted() {

        }
    }
</script>
