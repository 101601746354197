<template src="./usersMyProfileEditModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
    import states from '../../../dmFramework/dmJS/dmStates'

    export default {
        name: 'users-my-profile-edit-modal',
        mixins: [dmModalMixin],
        props: [
            'modalData',
        ],
        data: function () {
            return {
                modalName: 'usersMyProfileEditModal' + DM.getRandomGuid(),
                lblTitle: 'My Profile',
                emailAddress: '',
                currentPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                stateOptions: states,
                zipCode: '',
                phone: '',
                hospital: '',
                serviceType: '',
            }
        },
        methods: {
            saveUser() {
                let data = DM.copy(this.modalData.user);

                delete data.UserID;
                delete data.UserEmailAddress;
                delete data.FirstName;
                delete data.LastName;
                delete data.UserCreatedUtc;
                delete data.UserUpdatedUtc;
                delete data.UserIsDeleted;
                delete data.UserAdminNotes;
                delete data.UserFacilities;
                delete data.UserType;
                delete data.ServiceTypeID;

                Object.assign(data, {
                    Password: this.modalData.mode === 'edit' ? undefined : this.password,
                    PasswordConfirm: this.modalData.mode === 'edit' ? undefined : this.passwordConfirm,
                    UserAddress1: this.address1,
                    UserAddress2: this.address2,
                    UserCity: this.city,
                    UserState: this.state || '',
                    UserZipCode: this.zipCode,
                    UserMobilePhone: this.phone,
                });

                return DM.http({
                    method: 'POST',
                    url: '/Users/UpdateSelf',
                    data: data
                });
            },
            btnSaveOnClick() {
                let saveUser = () => {
                    this.saveUser()
                        .then(response => {
                            this.modalRef.hide();
                        });
                };

                if(this.currentPassword || this.newPassword || this.newPasswordConfirm) {
                    this.updatePassword()
                        .then(response => {
                            saveUser();
                        });
                } else {
                    saveUser();
                }
            },
            updatePassword() {
                return DM.http({
                    method: 'POST',
                    url: '/Users/UpdatePassword',
                    data: {
                        CurrentPassword: this.currentPassword,
                        NewPassword: this.newPassword,
                        NewPasswordConfirm: this.newPasswordConfirm

                    }
                })
                .then(response => {
                    return DM.http({
                        method: 'GET',
                        url: '/Users/GetByID',
                        params: {
                            UserID: app.signedInUser.UserID
                        }
                    })
                        .then(response => {
                            this.modalData.user.UserRowVersion = response.UserRowVersion;
                        });
                })
            },
            btnCancelOnClick() {
                this.modalRef.hide();
            },
            getServiceTypeOptions(facilityID) {
                return DM.http({
                    method: 'GET',
                    url: '/ServiceTypes/GetByFacilityID',
                    params: {
                        FacilityID: facilityID
                    }
                }).then(response => {
                    response.forEach(type => {
                        if(type.ServiceTypeID === this.modalData.user.ServiceTypeID) {
                            this.serviceType = type.ServiceTypeName;
                        }
                    });
                });
            },
        },
        created() {
            let user = this.modalData.user;

            this.mode = this.modalData.mode;

            this.emailAddress = user.UserEmailAddress;
            this.firstName = user.FirstName;
            this.lastName = user.LastName;
            this.address1 = user.UserAddress1;
            this.address2 = user.UserAddress2;
            this.city = user.UserCity;
            this.state = user.UserState;
            this.zipCode = user.UserZipCode;
            this.phone = user.UserMobilePhone;

            if(this.modalData.user.UserType !== 'Admin') {
                let promises = [];

                user.UserFacilities.forEach(h => {
                    promises.push(DM.http({
                        method: 'GET',
                        url: '/Facilities/GetByID',
                        params: {
                            FacilityID: h.FacilityID
                        }
                    }));
                });

                Promise.all(promises)
                    .then(responses => {
                        this.hospital = responses.map(r => r.FacilityName).join(', ');
                    });
            }

            if(this.modalData.user.UserType === 'Therapist') {
                DM.http({
                    method: 'GET',
                    url: '/ServiceTypes/GetByFacilityID',
                    params: {
                        FacilityID: user.UserFacilities[0].FacilityID
                    }
                }).then(response => {
                    this.getServiceTypeOptions(user.UserFacilities[0].FacilityID);
                });
            }
        },
        mounted() {

        }
    }
</script>
