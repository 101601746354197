<template src="./supportModal.html"></template>

<script>
import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'support-modal',
    mixins: [dmModalMixin],
    props: [
        "modalData",
    ],
    data: function () {
        return {
            modalName: 'supportModal' + DM.getRandomGuid(),
            lblTitle: 'How can we help?',
            name: '',
            number: '',
            emailAddress: '',
            message: '',
        }
    },
    methods: {
        sendMessage() {
            return DM.http({
                method: "POST",
                url: '/ContactUs/SubmitForm',
                data: {
                    Name: this.name,
                    PhoneNumber: this.number,
                    EmailAddress: this.emailAddress,
                    Message: this.message,
                }
            });
        },
        btnSaveOnClick() {
            this.sendMessage()
                .then(response => {
                    this.modalRef.hide();
                });
        },
        btnCancelOnClick() {
            this.modalRef.hide();
        }
    },
    created() {

    },
    mounted() {

    }
}
</script>
